import React from "react";
import { ButtonBase, FormLabel } from "@material-ui/core";
import Flex from "../layouts/Flex";
import useStyles from "./styles/TextButton";
import clsx from "clsx";

type ButtonType = "primary" | "black" | "gray";
type ButtonSize = "lg" | "md" | "sm";
export type TextButtonProps = {
  customId?: string;
  /** 버튼 컬러 타입 */
  buttonType?: ButtonType;
  /** 버튼 크기 - height로 판단 [36,32,24] 순서 */
  buttonSize?: ButtonSize;
  /**비활성화 */
  disabled?: boolean;
  className?: string;
  dataTestid?: string;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  /**form label for 설정 */
  htmlFor?: string;
  /** 내부 아이콘의 Class */
  iconClass?: string;
  /** 좌측 아이콘, svg 추천 */
  startIcon?: React.ReactNode;
  /** 우측 아이콘, svg 추천 */
  endIcon?: React.ReactNode;
  /** 버튼 안의 내용 - 텍스트 */
  children?: React.ReactNode;
  /** 텍스트 하단 라인 여부 */
  underLine?: boolean;
};

/**
  * - Background && Border 가 없는 버튼
  
*/
const TextButton: React.FC<TextButtonProps> = (props: TextButtonProps) => {
  const {
    customId,
    buttonType,
    buttonSize,
    disabled,
    className,
    dataTestid,
    onClick,
    htmlFor,
    iconClass,
    startIcon,
    endIcon,
    children,
    underLine,
  } = props;

  const classes = useStyles();

  const getSizeClass = () => {
    switch (buttonSize) {
      case "lg":
        return [classes.btn_lg];
      case "md":
        return [classes.btn_md];
      case "sm":
        return [classes.btn_sm];
      default:
        return [classes.btn_lg];
    }
  };
  const getColorClass = () => {
    switch (buttonType) {
      case "primary":
        return [classes.primary];
      case "black":
        return [classes.black];
      case "gray":
        return [classes.gray];
      default:
        return [classes.black];
    }
  };

  const component =
    htmlFor !== undefined && htmlFor && (disabled ?? true)
      ? { component: FormLabel, htmlFor }
      : {};
  return (
    <ButtonBase
      {...component}
      id={customId}
      data-testid={dataTestid}
      disabled={disabled}
      className={clsx(
        classes.btn_base,
        ...getColorClass(),
        ...getSizeClass(),
        underLine != undefined && underLine ? classes.underLine : undefined,
        className
      )}
      onClick={onClick}
      disableRipple
    >
      {startIcon != undefined && startIcon && (
        <Flex
          container
          alignItems="center"
          justifyContent="center"
          id={customId}
          className={clsx(classes.icon_base, classes.icon_start, iconClass)}
        >
          {startIcon}
        </Flex>
      )}
      {children}
      {endIcon != undefined && endIcon && (
        <Flex
          container
          alignItems="center"
          justifyContent="center"
          id={customId}
          className={clsx(classes.icon_base, classes.icon_end, iconClass)}
        >
          {endIcon}
        </Flex>
      )}
    </ButtonBase>
  );
};

TextButton.defaultProps = {
  buttonType: "black",
  buttonSize: "lg",
};

export default TextButton;
