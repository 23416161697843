import { IpfsData, IpfsDataSchema } from "../api/object/ipfs";
import T from "@redwit-commons/utils/typecheck";

export type UserSummary = {
  id: string;
  email: string;
  name: string;
  institution: string;
  department: string;
  profile: IpfsData;
};

export const UserSummarySchema = T.object()
  .addField("id", T.string())
  .addField("email", T.string())
  .addField("name", T.string())
  .addField("institution", T.string())
  .addField("department", T.string())
  .addField("profile", IpfsDataSchema.clone());

export enum DocumentTransformStatus {
  // 파일 변환 실패
  TRANSFORM_FAILED = "TRANSFORM_FAILED",
  // 파일 변환 완료
  COMPLETED = "COMPLETED",
}

export type Document = {
  id: string;
  ipfsData: IpfsData;
  fileName: string;
  fileOriginCid: string;
  fileOriginExtension: string;
  linkedCount: number;
  createdAt: string;
  creator: UserSummary;
  documentBoxId: string;
  documentBoxFolderId?: string;
  documentBoxName?: string;
  documentBoxFolderName?: string;
  // 값이 undefined 일 경우, 파일 변환중
  transformStatus?: DocumentTransformStatus;
};

export const DocumentSchema = T.object()
  .addField("id", T.string())
  .addField("ipfsData", IpfsDataSchema.clone())
  .addField("fileName", T.string())
  .addField("fileOriginCid", T.string())
  .addField("fileOriginExtension", T.string())
  .addField("linkedCount", T.number())
  .addField("createdAt", T.string())
  .addField("creator", UserSummarySchema.clone())
  .addField("documentBoxId", T.string())
  .addField("documentBoxFolderId", T.string(), false)
  .addField("documentBoxName", T.string(), false)
  .addField("documentBoxFolderName", T.string(), false)
  .addField(
    "transformStatus",
    T.string().withEnum([
      DocumentTransformStatus.COMPLETED,
      DocumentTransformStatus.TRANSFORM_FAILED,
    ]),
    false
  );

export type DocumentSummaryCore = {
  id: string;
  documentBoxId: string;
  fileName: string;
  fileOriginalExtension: string;
};

export type DocumentSummary = DocumentSummaryCore & {
  ipfsData: IpfsData;
  documentBoxName: string;
  documentFolderName?: string;
};

export const DocumentSummarySchema = T.object()
  .addField("id", T.string())
  .addField("fileName", T.string())
  .addField("ipfsData", IpfsDataSchema.clone())
  .addField("fileOriginalExtension", T.string())
  .addField("documentBoxName", T.string())
  .addField("documentFolderName", T.string(), false);
