import { lazy } from "react";
import ScreenURL from "./route_list";

export const auth_route = [
  {
    path: ScreenURL.LOGIN,
    element: lazy(() => import("src/screens/auth/SignInScreen")),
  },
];

export const service_paths = [
  {
    path: ScreenURL.MANAGE_USER,
    element: lazy(() => import("src/screens/manage/ManageUserScreen")),
  },
  {
    path: ScreenURL.MANAGE_WORKSPACE,
    element: lazy(() => import("src/screens/manage/ManageWorkspaceScreen")),
  },
  {
    path: ScreenURL.MANAGE_ORGANIZATION,
    element: lazy(() => import("src/screens/manage/ManageOrganizationScreen")),
  },
  {
    path: ScreenURL.MANAGE_ORGANIZATION_ADMIN,
    element: lazy(
      () => import("src/screens/manage/ManageOrganizationAdminScreen")
    ),
  },
  {
    path: ScreenURL.WORKSPACE_CREATE,
    element: lazy(
      () => import("src/screens/configuration/WorkspaceConfigurationScreen")
    ),
  },
  {
    path: ScreenURL.WORKSPACE_EDIT,
    element: lazy(
      () => import("src/screens/configuration/WorkspaceConfigurationScreen")
    ),
  },

  {
    path: ScreenURL.ORGANIZATION_CREATE,
    element: lazy(
      () => import("src/screens/configuration/OrganizationConfigurationScreen")
    ),
  },
  {
    path: ScreenURL.ORGANIZATION_EDIT,
    element: lazy(
      () => import("src/screens/configuration/OrganizationConfigurationScreen")
    ),
  },
  {
    path: ScreenURL.PAYMENT_WINDOW_CREATION,
    element: lazy(
      () => import("src/screens/payment/PaymentWindowCreationScreen")
    ),
  },
  {
    path: ScreenURL.PAYMENT_WINDOW_CREATION_HISTORY,
    element: lazy(
      () => import("src/screens/payment/PaymentWindowCreationHistoryScreen")
    ),
  },
  {
    path: ScreenURL.ACCESS,
    element: lazy(() => import("src/screens/legacy/AccessScreen")),
  },
  {
    path: ScreenURL.DASHBOARD,
    element: lazy(() => import("src/screens/legacy/DashBoardScreen2")),
  },
  {
    path: ScreenURL.REPORT_USER_LOG,
    element: lazy(() => import("src/screens/legacy/UserLogScreen")),
  },
];

export const fallback_route = [
  {
    path: "/*",
    element: lazy(() => import("src/screens/common/ForbiddenScreen")),
  },
];
