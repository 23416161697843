import {
  ActiveWorkspaceMemberLog,
  ActiveWorkspaceMemberLogSchema,
  MostUpdatedProject,
  MostUpdatedProjectSchema,
  ResearcherNoteLog,
  ResearcherNoteLogSchema,
  TagLog,
  TagLogSchema,
} from "../adminDashboard";
import T from "@redwit-commons/utils/typecheck";

export type OGetAdminNoteLogs = {
  researcherNoteLogs: ResearcherNoteLog[];
  mostUpdatedProjects: MostUpdatedProject[];
};

export const OGetAdminNoteLogsSchema = T.object()
  .addField(`researcherNoteLogs`, T.array(ResearcherNoteLogSchema))
  .addField(`mostUpdatedProjects`, T.array(MostUpdatedProjectSchema));

export const validateOGetAdminNoteLogs = T.mkValidator<OGetAdminNoteLogs>(
  OGetAdminNoteLogsSchema
);

export const validateOGetAdminUploaderLog =
  T.mkValidator<ActiveWorkspaceMemberLog>(ActiveWorkspaceMemberLogSchema);

export type OGetAdminTagLogs = TagLog[];

export const OGetAdminTagLogsSchema = T.array(TagLogSchema);

export const validateOGetAdminTagLogs = T.mkValidator<OGetAdminTagLogs>(
  OGetAdminTagLogsSchema
);
