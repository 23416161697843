import { SNSPlatform, SNSPlatformList } from "../api/object/user";
import {
  UserLatestVisitLog,
  Workspace,
  WorkspaceMemberInfoSchema,
  WorkspaceMemberRoleSchema,
  WorkspaceMemberVisitLogSchema,
  WorkspacePlan,
  WorkspacePlanKind,
  WorkspacePlanSchema,
  WorkspaceRoleKind,
  WorkspaceSchema,
} from "./workspace";
import T from "@redwit-commons/utils/typecheck";
import { IpfsData, IpfsDataSchema } from "../api/object/ipfs";
import { AdminActivityType } from "../api/object/user_log";
import { DocumentBox, DocumentBoxSchema } from "../dataRoom/documentBox";
import { TaskObject, TaskStatus } from "@redwit-commons/object/task";
import { withAccessTime } from "@redwit-commons/object/access_time";
import {
  AdminTaskInput,
  AdminTaskInputSchema,
  AdminTaskOutput,
  AdminTaskOutputSchema,
} from "../api/object/taskv2/admin";

export type PaginationQueryParams = {
  limit: number;
  offset: number;
};

export type AdminSearchBy =
  | "all"
  | "name"
  | "workspace_name"
  | "email"
  | "school"
  | "department"
  | "institution";
export type SearchQueryParams = {
  search: string;
  searchBy: AdminSearchBy;
};

export type AdminWorkspacesOrderBy = "createdAt" | "end";
export type OrderQueryParams = {
  order: "ASC" | "DESC";
  orderBy: AdminWorkspacesOrderBy;
};

export type AdminUsersFilter = "all" | "active" | "inactive";
export type AdminUsersQueryParams = PaginationQueryParams &
  SearchQueryParams & {
    filter: AdminUsersFilter;
    marketingAgreement: boolean;
    userIds: string;
    excludeWorkspaceIds: string; // string in format of JSON array (ex. '["a","b","c"]')
  };
export type AdminUsersQueryOptions = Partial<AdminUsersQueryParams>;

export type AdminUserLogs = {
  latestVisit?: UserLatestVisitLog;
  researchNoteUploadCount?: number;
  researchNoteDownloadCount?: number
  dataRoomUploadCount?: number;
};

export type AdminUser = AdminUserLogs & {
  id: string;
  name: string;
  email: string;
  profile_cid: string;
  profile_extension: string;
  CipherId?: string;
  platform: SNSPlatform;
  gender: string;
  school: string;
  department: string;
  marketingTerm: boolean;
  deactivated: boolean;
  createdAt: string;
  updatedAt: string;
  workspaces: UserWorkspaceSummary[];
};

export type UserWorkspaceSummary = AdminWorkspace & {
  role: WorkspaceRoleKind;
  joinedAt: string;
};

export type AdminWorkspacesQueryParams = PaginationQueryParams &
  SearchQueryParams &
  OrderQueryParams & {
    plan: string; // join with `|` to get multiple WorkspaceFilterType
    service: string; // join with `|` to get multiple WorkspacePlanServiceEnum
    status: string; // "past" | "current" | "future"; // join with `|` to get multiple types
    memberRole: string; // join with `|` to get multiple WorkspaceMemberRoleFilterType
    startBefore: string;
    endAfter: string;
    aboutToExpire: "month" | "week" | "day";
    amountExpire: number;
    workspaceIds: string; // string in format of JSON array (ex. '["a","b","c"]')
    excludeIds: string; // string in format of JSON array (ex. '["a","b","c"]')
  };
export type AdminWorkspacesQueryOptions = Partial<AdminWorkspacesQueryParams>;

export type AdminWorkspaceMemberAccessLogCsvQuery =
  Partial<PaginationQueryParams> & {
    workspaceId: string;
    from: string;
    to: string;
  };

export type AdminWorkspaceMembersQueryParams = PaginationQueryParams & {
  search: string;
  memberRole: string; // join with `|` to get multiple WorkspaceMemberRoleFilterType
};
export type AdminWorkspaceMembersQueryOptions =
  Partial<AdminWorkspaceMembersQueryParams>;

export type AdminWorkspaceMemberWithLogData = AdminUserLogs & {
  id: string;
  role: WorkspaceRoleKind;
  name: string;
  email: string;
  school: string;
  department: string;
  profile_cid: string;
  profile_extension: string;
  createdAt: string;
  CipherId?: string;
};

export type AdminWorkspace = {
  id: string;
  name: string;
  institution?: string;
  thumbnail?: IpfsData;
  thumbColor?: string;
  createdAt: string;
  plan?: WorkspacePlan;
  memberCount: number;
};

export type WorkspaceMemberSummary = {
  id: string;
  role: WorkspaceRoleKind;
};

export type AdminCreateWorkspaceDto = {
  name: string;
  thumbnail?: IpfsData;
  thumbColor?: string;
  institution?: string;
  plan?: WorkspacePlan;
  members: WorkspaceMemberSummary[];
};

export type AdminUpdateWorkspaceDto = {
  id: string;
  name?: string;
  institution?: string;
  thumbnail?: IpfsData;
};

export type AdminWorkspaceBulkOperationQueryParams = Omit<
  AdminWorkspacesQueryParams,
  "limit" | "offset"
>;
export type AdminWorkspaceBulkOperationQueryOptions =
  Partial<PaginationQueryParams> &
    Partial<AdminWorkspaceBulkOperationQueryParams>;

export type AdminActivityQueryParams = PaginationQueryParams & {
  filter?: string;
  search: string;
};
export type AdminActivityQueryOptions = Partial<AdminActivityQueryParams>;
export type UpdatePlanInfo = {
  prevInfo: Partial<WorkspacePlan>;
  newInfo: Partial<WorkspacePlan>;
};
export type UpdateWorkspaceInfo = {
  prevInfo: Partial<AdminWorkspace>;
  newInfo: Partial<AdminWorkspace>;
};
export type AdminActivityMeta = {
  creatorName?: string;
  workspaceName?: string;
  updatePlan?: UpdatePlanInfo;
  updateWorkspace?: UpdateWorkspaceInfo;
};

export type AdminActivity = {
  type: AdminActivityType;
  workspaceId: string;
  userId: string;
  createdAt: string;
  metadata: AdminActivityMeta;
};

export type AdminOrganizationAdminsQueryParams = PaginationQueryParams &
  Omit<SearchQueryParams, "searchBy">;
export type AdminOrganizationAdminsQueryOptions =
  Partial<AdminOrganizationAdminsQueryParams>;

export type AdminOrganizationAdmin = {
  id: string;
  name: string;
  email: string;
  createdAt: string;
  lastVisit?: UserLatestVisitLog;
};

export type AdminOrganizationSummary = {
  id: string;
  name: string;
  createdAt: string;
  workspaces?: string[];
  admins?: string[];
};

export type AdminCreateOrganizationDto = {
  name: string;
  workspaces?: string[];
  admins?: string[];
};

export type AdminUpdateOrganizationDto = {
  id: string;
  name?: string;
};

export type AdminCreateOrganizationAdminDto = {
  name: string;
  email: string;
  password: string;
};

export type AdminUpdateOrganizationAdminDto =
  Partial<AdminCreateOrganizationAdminDto> & {
    id: string;
  };

export type AdminDocumentBoxDto = {
  id: string;
  name: string;
};

export type AdminGetBulkUpdateTaskObject = Partial<
  Omit<TaskObject, "input" | "output" | "exception"> & {
    input: AdminTaskInput;
    output: AdminTaskOutput;
    exception: object;
  }
>;

/** ---- api type ---- */

export type GetAdminUsers = {
  total: number;
  results: AdminUser[];
};

export type GetAdminWorkspaces = {
  total: number;
  results: AdminWorkspace[];
};

export type GetAdminWorkspaceActivityLog = {
  total: number;
  results: AdminActivity[];
};

export type UpdateAdminWorkspaceMembersParams = {
  users: WorkspaceMemberSummary[];
};

export type GetAdminOrganizations = {
  total: number;
  results: AdminOrganizationSummary[];
};

export type GetAdminOrganizationAdmins = {
  total: number;
  results: AdminOrganizationAdmin[];
};

export type CreateWorkspaceResult = {
  workspaceId: string;
};

export type CSVResult = {
  total: number;
  csv: string;
};

/** ---- schema & validate ---- */

export const AdminWorkspaceObjectSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("institution", T.string(), false)
  .addField("thumbnail", IpfsDataSchema.clone(), false)
  .addField("thumbColor", T.string(), false)
  .addField("createdAt", T.string())
  .addField("plan", WorkspacePlanSchema.clone(), false)
  .addField(
    "members",
    T.array(
      T.object()
        .addField("id", T.string())
        .addField("role", WorkspaceMemberRoleSchema.clone())
        .addField("name", T.string())
        .addField("email", T.string())
        .addField("school", T.string())
        .addField("department", T.string())
        .addField("profile_cid", T.string())
        .addField("profile_extension", T.string())
        .addField("createdAt", T.string())
        .addField("CipherId", T.string(), false)
        .addField("latestVisit", WorkspaceMemberVisitLogSchema.clone(), false)
    ),
    false
  );
export const GetAdminWorkspacesSchema = T.object()
  .addField("total", T.integer())
  .addField("results", T.array(AdminWorkspaceObjectSchema.clone()));
export const validateGetAdminWorkspaces = T.mkValidator<GetAdminWorkspaces>(
  GetAdminWorkspacesSchema
);

export const AdminUserObjectSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("email", T.string())
  .addField("profile_cid", T.string())
  .addField("profile_extension", T.string())
  .addField("CipherId", T.string(), false)
  .addField("platform", T.string().withEnum(SNSPlatformList))
  .addField("gender", T.string())
  .addField("school", T.string())
  .addField("department", T.string())
  .addField("marketingTerm", T.boolean())
  .addField("deactivated", T.boolean())
  .addField("createdAt", T.string())
  .addField("updatedAt", T.string())
  .addField("latestVisit", WorkspaceMemberVisitLogSchema.clone())
  .addField("researchNoteUploadCount", T.number(), false)
  .addField("researchNoteDownloadCount", T.number(), false)
  .addField("dataRoomUploadCount", T.number(), false)
  .addField(
    "workspaces",
    T.array(
      AdminWorkspaceObjectSchema.clone()
        .addField("role", WorkspaceMemberRoleSchema.clone())
        .addField("joinedAt", T.string())
    )
  );
export const GetAdminUsersSchema = T.object()
  .addField("total", T.integer())
  .addField("results", T.array(AdminUserObjectSchema.clone()));
export const validateGetAdminUsers =
  T.mkValidator<GetAdminUsers>(GetAdminUsersSchema);

export const GetAdminUserSchema = AdminUserObjectSchema.clone();
export const validateGetAdminUser =
  T.mkValidator<AdminUser>(GetAdminUserSchema);

export const GetAdminWorkspaceSchema = AdminWorkspaceObjectSchema.clone();
export const validateGetAdminWorkspace = T.mkValidator<AdminWorkspace>(
  GetAdminWorkspaceSchema
);

export const AdminWorkspaceMemberWithLogDataSchema = T.object()
  .addField("id", T.string())
  .addField("role", WorkspaceMemberRoleSchema.clone())
  .addField("name", T.string())
  .addField("email", T.string())
  .addField("school", T.string())
  .addField("department", T.string())
  .addField("profile_cid", T.string())
  .addField("profile_extension", T.string())
  .addField("createdAt", T.string())
  .addField("CipherId", T.string(), false);

export const UpdatePlanSchema = T.object()
  .addField("name", T.string(), false)
  .addField(
    "type",
    T.string().withEnum([
      WorkspacePlanKind.PERSONAL,
      WorkspacePlanKind.TEAM,
      WorkspacePlanKind.TRIAL,
      WorkspacePlanKind.ENTERPRISE,
      WorkspacePlanKind.ENTERPRISE_PLUS,
    ]),
    false
  )
  .addField(
    "feature",
    T.object()
      .addField("researchNote", T.boolean())
      .addField("dataRoom", T.boolean()),
    false
  )
  .addField("seats", T.number(), false)
  .addField(
    "period",
    T.object().addField("startAt", T.string()).addField("endAt", T.string()),
    false
  )
  .addField("price", T.string(), false)
  .addField("description", T.string(), false)
  .addField(
    "aclOption",
    T.object()
      .addField("enableACL", T.boolean(), false)
      .addField("applyAdmin", T.boolean(), false),
    false
  );

export const UpdateWorkspaceSchema = T.object()
  .addField("id", T.string(), false)
  .addField("name", T.string(), false)
  .addField("members", T.array(WorkspaceMemberInfoSchema.clone()), false)
  .addField("institution", T.string(), false)
  .addField("createdAt", T.string(), false)
  .addField("plan", WorkspacePlanSchema.clone(), false)
  .addField("thumbColor", T.string(), false)
  .addField("thumbnail", IpfsDataSchema.clone(), false);

export const AdminWorkspaceActivityLogObjectSchema = T.object()
  .addField(
    "type",
    T.string().withEnum([
      AdminActivityType.START_TRIAL,
      AdminActivityType.EXPIRE_PLAN,
      AdminActivityType.RENEW_PLAN,
      AdminActivityType.UPDATE_WORKSPACE,
    ])
  )
  .addField("workspaceId", T.string())
  .addField("userId", T.string())
  .addField("createdAt", T.string())
  .addField(
    "metadata",
    T.object()
      .addField("creatorName", T.string(), false)
      .addField("workspaceName", T.string(), false)
      .addField(
        "updatePlan",
        T.object()
          .addField("prevInfo", UpdatePlanSchema.clone())
          .addField("newInfo", UpdatePlanSchema.clone()),
        false
      )
      .addField(
        "updateWorkspace",
        T.object()
          .addField("prevInfo", UpdateWorkspaceSchema.clone())
          .addField("newInfo", UpdateWorkspaceSchema.clone()),
        false
      )
  );

export const GetAdminWorkspaceActivityLogSchema = T.object()
  .addField("total", T.integer())
  .addField("results", T.array(AdminWorkspaceActivityLogObjectSchema.clone()));
export const validateGetAdminWorkspaceActivityLog =
  T.mkValidator<GetAdminWorkspaceActivityLog>(
    GetAdminWorkspaceActivityLogSchema
  );

export const AdminAdminsObjectSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("email", T.string())
  .addField("createdAt", T.string())
  .addField("lastVisit", WorkspaceMemberVisitLogSchema.clone(), false);
export const AdminOrganizationSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("createdAt", T.string())
  .addField("workspaces", T.array(T.string()), false)
  .addField("admins", T.array(T.string()), false);
export const GetAdminOrganizationsSchema = T.object()
  .addField("total", T.integer())
  .addField("results", T.array(AdminOrganizationSchema.clone()));
export const validateGetAdminOrganizations =
  T.mkValidator<GetAdminOrganizations>(GetAdminOrganizationsSchema);

export const GetAdminOrganizationSchema = AdminOrganizationSchema.clone();
export const validateGetAdminOrganization =
  T.mkValidator<AdminOrganizationSummary>(GetAdminOrganizationSchema);

export const GetAdminOrganizationAdminsSchema = T.object()
  .addField("total", T.integer())
  .addField("results", T.array(AdminAdminsObjectSchema.clone()));
export const validateGetAdminOrganizationAdmins =
  T.mkValidator<GetAdminOrganizationAdmins>(GetAdminOrganizationAdminsSchema);

export const validateGetAdminOrganizationOwnAdmins = T.mkValidator<
  AdminOrganizationAdmin[]
>(T.array(AdminAdminsObjectSchema.clone()));
export const validateGetAdminOrganizationOwnWorkspaces = T.mkValidator<
  Workspace[]
>(T.array(WorkspaceSchema.clone()));

export const validateCreateWorkspace = T.mkValidator<CreateWorkspaceResult>(
  T.object().addField("workspaceId", T.string())
);

export const GetUserOwnerDocumentBoxListSchema = T.array(
  DocumentBoxSchema.clone()
);

export const validateGetUserOwnerDocumentBoxList = T.mkValidator<DocumentBox[]>(
  GetUserOwnerDocumentBoxListSchema
);

export const validateGetCSVFile = T.mkValidator<CSVResult>(
  T.object().addField("total", T.integer().addField("csv", T.string()))
);

export const GetBulkUpdateTaskSchema = withAccessTime()
  .addField("id", T.string())
  .addField("kind", T.string())
  .addField("status", T.string().withEnum(Object.values(TaskStatus)))
  .addField("triggered_by", T.string())
  .addField("input", AdminTaskInputSchema.clone())
  .addField("output", AdminTaskOutputSchema.clone())
  .addField("exception", T.object())
  .addField("stage", T.string())
  .addField("current", T.string())
  .addField("total", T.string());

const GetBulkUpdateTasksSchema = T.array(GetBulkUpdateTaskSchema.clone());
export const validateGetBulkUpdateTasks = T.mkValidator<
  AdminGetBulkUpdateTaskObject[]
>(GetBulkUpdateTasksSchema);
