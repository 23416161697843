import {
  AdminWorkspaceMemberWithLogData,
  AdminWorkspaceMemberWithLogDataSchema,
} from "../goonoAdmin";
import T from "@redwit-commons/utils/typecheck";

export type OGetAdminWorkspaceMembers = {
  total: number;
  results: AdminWorkspaceMemberWithLogData[];
};

export const OGetAdminWorkspaceMembersSchema = T.object()
  .addField("total", T.integer())
  .addField("results", T.array(AdminWorkspaceMemberWithLogDataSchema.clone()));

export const validateOGetAdminWorkspaceMembers =
  T.mkValidator<OGetAdminWorkspaceMembers>(OGetAdminWorkspaceMembersSchema);
