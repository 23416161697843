import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  goonoEmailCheckRegister,
  goonoLogin,
  GoonoLoginArgs,
  goonoLoginType,
  goonoProfile,
} from "@goono-react-commons/services/user";
import {
  doTokenAction,
  resetToken,
  TokenActionKind,
} from "src/redux/store/reducers/token";
import {
  emailLoginErrorType,
  snsLoginErrorType,
} from "@goono-commons/api/response/user";
import { delay } from "@redwit-commons/utils/function";
import ScreenURL from "src/routes/route_list";

export type LoginErrorResponse = {
  msg: string;
};

export const useLoginMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleError = (msg: string) => {
    throw { msg } as LoginErrorResponse;
  };

  return useMutation(async (props: GoonoLoginArgs) => {
    const ret = await goonoLogin(props);

    /** error handling */
    if (ret.needRegister) {
      if (props.type === goonoLoginType.EMAIL) {
        const check = await goonoEmailCheckRegister({
          email: props.args.email,
        });

        const errorMsg =
          !check.response.available &&
          check.response.platform &&
          check.response.platform !== "email"
            ? `다른 플랫폼으로 가입된 계정입니다.`
            : `가입되지 않은 계정 입니다`;

        handleError(errorMsg);
      } else {
        handleError(`계정정보가 일치하지 않습니다.`);
      }
    } else if (ret.errorMessage) {
      switch (ret.errorMessage) {
        case emailLoginErrorType.NOT_VERIFIED:
          handleError(`인증되지 않은 계정입니다.`);
          break;
        case emailLoginErrorType.INVALID_PASSWORD:
          handleError(`비밀번호가 일치하지 않습니다.`);
          break;
        case emailLoginErrorType.SEND_MAIL_AGAIN:
          handleError(`인증 메일을 재 발송하였습니다. 메일함을 확인해주세요.`);
          break;
        case snsLoginErrorType.INCORRECT_PLATFORM:
          handleError(`다른 플랫폼으로 가입된 계정입니다.`);
          break;
      }
    }

    const profile = await goonoProfile(ret.token);
    queryClient.removeQueries();

    doTokenAction(dispatch, {
      kind: TokenActionKind.TRY_LOGIN,
      token: ret.token,
      ...profile.response,
    });
  });
};

export const useLogoutMutation = () => {
  const dispatch = useDispatch();

  return useMutation(async () => {
    resetToken(dispatch);
    await delay(100);
    window.location.replace(ScreenURL.LOGIN);
  });
};
