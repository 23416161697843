import React from "react";
import { Box } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useStyles from "../styles/HeaderMenuOption";

interface HeaderMenuOptionProps {
  navigateFirstPage: () => void;
  navigateSecondPage: () => void;
  firstOptionTitle: string;
  secondOptionTitle: string;
  className: string;
}

const HeaderMenuOption = ({
  navigateFirstPage,
  navigateSecondPage,
  firstOptionTitle,
  secondOptionTitle,
  className,
}: HeaderMenuOptionProps) => {
  const classes = useStyles();
  return (
    <Box className={className}>
      <div style={{ height: 4, width: "100%" }} />
      <Flex container flexDirection="column" className={classes.wrapper}>
        <Flex
          onClick={navigateFirstPage}
          className={classes.option}
          style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        >
          <GoonoTypography type="ele2_m" textcolor="text">
            {firstOptionTitle}
          </GoonoTypography>
        </Flex>
        <Flex
          onClick={navigateSecondPage}
          className={classes.option}
          style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
        >
          <GoonoTypography type="ele2_m" textcolor="text">
            {secondOptionTitle}
          </GoonoTypography>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HeaderMenuOption;
