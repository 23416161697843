import React from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import { RootState } from "src/redux/store/reducers";
import { ModalInfo, ModalType } from "src/redux/store/reducers/modal";

const UserInfoModal = loadable(() => import("src/modals/UserInfoModal"));
const SendEmailModal = loadable(() => import("src/modals/SendEmailModal"));
const UpdateOwnerModal = loadable(() => import("src/modals/UpdateOwnerModal"));
const DeleteUserModal = loadable(() => import("src/modals/DeleteUserModal"));
const DeleteWorkspaceModal = loadable(
  () => import("src/modals/DeleteWorkspaceModal")
);
const DeleteWorkspaceUserModal = loadable(
  () => import("src/modals/DeleteWorkspaceMemberModal")
);
const ManageUserModal = loadable(() => import("src/modals/ManageUserModal"));
const UpdateSingleWorkspacePlanModal = loadable(
  () =>
    import("src/modals/UpdateWorkspacePlanModal/UpdateSingleWorkspacePlanModal")
);
const BulkUpdateWorkspacePlanModal = loadable(
  () =>
    import("src/modals/UpdateWorkspacePlanModal/BulkUpdateWorkspacePlanModal")
);
const BulkUpdateWorkspaceMemberModal = loadable(
  () => import("src/modals/BulkUpdateWorkspaceMemberModal")
);
const SetMemberPermissionsModal = loadable(
  () => import("src/modals/SetMemberPermissionsModal")
);
const DeleteOwnerWarningModal = loadable(
  () => import("src/modals/DeleteOwnerWarningModal")
);
const DeleteOrganizationModal = loadable(
  () => import("src/modals/DeleteOrganizationModal")
);
const ManageOrganizationAdminModal = loadable(
  () => import("src/modals/ManageOrganizationAdminModal")
);
const ManageOrganizationWorkspaceModal = loadable(
  () => import("src/modals/ManageOrganizationWorkspaceModal")
);
const DeleteOrganizationAdminModal = loadable(
  () => import("src/modals/DeleteOrganizationAdminModal")
);
const CreateOrganizationAdminModal = loadable(
  () => import("src/modals/CreateOrganizationAdminModal")
);
const UpdateOrganizationAdminModal = loadable(
  () => import("src/modals/UpdateOrganizationAdminModal")
);
const DeleteWorkspaceMemberAlertModal = loadable(
  () => import("src/modals/DeleteWorkspaceMemberAlertModal")
);
const ReconfirmModal = loadable(() => import("src/modals/ReconfirmModal"));
const BulkUpdateHistoryModal = loadable(
  () => import("src/modals/BulkUpdateHistoryModal")
);
const SetDownloadDateModal = loadable(
  () => import("src/modals/SetDownloadDateModal")
);

export default function GlobalModalManager() {
  const modalState = useSelector((state: RootState) => state.modal.state);

  const renderModalContent = (modalInfo: ModalInfo) => {
    switch (modalInfo.type) {
      case ModalType.USER_INFO:
        return <UserInfoModal {...modalInfo} />;
      case ModalType.SEND_EMAIL:
        return <SendEmailModal {...modalInfo} />;
      case ModalType.UPDATE_OWNER:
        return <UpdateOwnerModal {...modalInfo} />;
      case ModalType.DELETE_USER:
        return <DeleteUserModal {...modalInfo} />;
      case ModalType.DELETE_WORKSPACE:
        return <DeleteWorkspaceModal {...modalInfo} />;
      case ModalType.DELETE_WORKSPACE_MEMBER:
        return <DeleteWorkspaceUserModal {...modalInfo} />;
      case ModalType.MANAGE_USER:
        return <ManageUserModal {...modalInfo} />;
      case ModalType.UPDATE_SINGLE_WORKSPACE_PLAN:
        return <UpdateSingleWorkspacePlanModal {...modalInfo} />;
      case ModalType.BULK_UPDATE_WORKSPACE_PLAN:
        return <BulkUpdateWorkspacePlanModal {...modalInfo} />;
      case ModalType.BULK_UPDATE_WORKSPACES_MEMBER:
        return <BulkUpdateWorkspaceMemberModal {...modalInfo} />;
      case ModalType.SET_MEMBER_PERMISSIONS:
        return <SetMemberPermissionsModal {...modalInfo} />;
      case ModalType.DELETE_OWNER_WARNING:
        return <DeleteOwnerWarningModal {...modalInfo} />;
      case ModalType.DELETE_ORGANIZATION:
        return <DeleteOrganizationModal {...modalInfo} />;
      case ModalType.MANAGE_ORGANIZATION_ADMIN:
        return <ManageOrganizationAdminModal {...modalInfo} />;
      case ModalType.MANAGE_ORGANIZATION_WORKSPACE:
        return <ManageOrganizationWorkspaceModal {...modalInfo} />;
      case ModalType.DELETE_ORGANIZATION_ADMIN:
        return <DeleteOrganizationAdminModal {...modalInfo} />;
      case ModalType.CREATE_ORGANIZATION_ADMIN:
        return <CreateOrganizationAdminModal />;
      case ModalType.UPDATE_ORGANIZATION_ADMIN:
        return <UpdateOrganizationAdminModal {...modalInfo} />;
      case ModalType.DELETE_WORKSPACE_MEMBER_ALERT:
        return <DeleteWorkspaceMemberAlertModal {...modalInfo} />;
      case ModalType.RECONFIRM:
        return <ReconfirmModal {...modalInfo} />;
      case ModalType.BULK_UPDATE_HISTORY:
        return <BulkUpdateHistoryModal />;
      case ModalType.SET_DOWNLOAD_DATE:
        return <SetDownloadDateModal {...modalInfo} />;
    }
  };

  return (
    <React.Fragment>
      {modalState.openedModalList.map((modalInfo) => (
        <React.Fragment key={modalInfo.type}>
          {renderModalContent(modalInfo)}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
