import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  ModalAction,
  ModalActionKind,
  ModalInfo,
  ModalState,
  modalStateMachine,
  ModalStateMachineType,
} from "../store/reducers/modal";
import _ from "lodash";

const mapStateToProps = (state: RootState) => {
  return { reduxState: state.modal };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ModalContainerProps = PropsFromRedux & {
  stateMachine: ModalStateMachineType;
};

export const handleModalAction = (
  prevState: ModalState,
  action: ModalAction
): ModalState => {
  switch (action.kind) {
    case ModalActionKind.TRY_OPEN: {
      return {
        ...prevState,
        openedModalList: [
          ...prevState.openedModalList,
          _.omit(action, "kind") as ModalInfo,
        ],
      };
    }
    case ModalActionKind.TRY_CLOSE: {
      if (prevState.openedModalList.length === 0) return prevState;

      const newOpenedModalList = prevState.openedModalList.slice(0, -1);
      return {
        ...prevState,
        openedModalList: newOpenedModalList,
      };
    }
    case ModalActionKind.TRY_CLOSE_ALL: {
      return {
        ...prevState,
        openedModalList: [],
      };
    }
  }
};

class ModalContainer extends ReduxStateComponent3<ModalContainerProps> {
  static defaultProps = { stateMachine: modalStateMachine };
  constructor(props: ModalContainerProps) {
    super(props);
  }

  protected onAction = handleModalAction;
}

export default connector(ModalContainer);
