import T from "../utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";

/**
 * Task 생성 시 반드시 필요한 데이터
 */
type TaskRequiredData = {
  /**
   * Task 고유 ID
   */
  readonly id: string;
  /**
   * Task 종류. 주로 Worker 의 이름이 사용됨.
   */
  readonly kind: string;
  /**
   * 현재 이 Task 의 상태
   */
  readonly status: TaskStatus;
  /**
   * 이 Task 를 시작한 주체.
   * IPFS Files 와 다르게 Range match 가 아니라 exact match 위주로 검색을 진행한다.
   *
   * 예시: `/goono_service/user/123-123`
   */
  readonly triggered_by: string;
  /**
   * Input json data as string
   */
  readonly input: string;
  /**
   * Output json data as string
   */
  readonly output: string;
  /**
   * Exception json data as string
   */
  readonly exception: string;
};

export const DefaultTaskStage = "TaskStage::Initialize";

/**
 * Task 생성시 주어지지 않더라도 디폴트로 채워지는 값
 */
export type TaskStageData = {
  /**
   * 현재 이 Task 의 진행 단계.
   *
   * @default DefaultTaskStage
   */
  readonly stage: string;

  /**
   * 숫자로 나타낸 현재 stage 의 진행 척도.
   * 반드시 정수형 자료를 기입해야 함.
   *
   * @default 1
   */
  readonly current: number;
  /**
   * 숫자로 나타낸 현재 stage 의 총 진행 범위.
   * 반드시 정수형 자료를 기입해야 함.
   *
   * @default 1
   */
  readonly total: number;
};

/**
 * Task Object 를 생성할 수 있는 core 데이터
 */
export type TaskCore = TaskRequiredData & Partial<TaskStageData>;

export type TaskObject = AccessTime & TaskRequiredData & TaskStageData;

/**
 * Bull queue 에서 사용할 메시지 타입 (통일)
 */
export type TaskMsg = Pick<TaskObject, "id" | "status" | "kind">;

export const extractTaskMsg = (task: TaskMsg) => {
  return {
    id: task.id,
    status: task.status,
    kind: task.kind,
  };
};

export enum TaskStatus {
  /**
   * Task 가 DB에 생성만 된 상태
   */
  Initialized = "Task::INITIALIZED",
  /**
   * Task 를 담당할 Task manager 가 할당되어 전처리를 진행하는 단계
   */
  PreProcessing = "Task::PREPROCESSING",
  /**
   * Preprocess가 종료된 후 Worker 가 assign되기를 기다리는 단계
   */
  Scheduled = "Task::SCHEDULED",
  /**
   * Task 가 Worker 에 assign 되어 연산이 진행되는 단계
   */
  Processing = "Task::PROCESSING",
  /**
   * Worker 의 작업은 완료되었고, Task manager 의 후처리를 기다리는 단계
   */
  Processed = "Task::PROCESSED",
  /**
   * 후처리를 담당하는 Task Manager 가 assign 되어 작업을 진행중인 단계
   */
  PostProcessing = "Task::POSTPROCESSING",
  /**
   * Task Manager 가 모든 작업을 마치고 최종적으로 Task 가 완료된 상태
   */
  Completed = "Task::COMPLETED",
  /**
   * 모종의 이유로 Task 가 취소된 상태
   */
  Cancelled = "Task::CANCELLED",
}

export const TaskObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("kind", T.string())
  .addField("status", T.string().withEnum(Object.values(TaskStatus)))
  .addField("triggered_by", T.string())
  .addField("input", T.string())
  .addField("output", T.string())
  .addField("exception", T.string())
  .addField("stage", T.string())
  .addField("current", T.string())
  .addField("total", T.string());

export const extractTaskObject =
  T.mkObjectExtractor<TaskObject>(TaskObjectSchema);

export type ParsedTaskObject = Omit<
  TaskObject,
  "input" | "output" | "exception"
> & { input: object; output: object; exception: object };
export const parseTaskObject = (from: TaskObject): ParsedTaskObject => {
  return {
    ...from,
    input: JSON.parse(from.input),
    output: JSON.parse(from.output),
    exception: JSON.parse(from.exception),
  };
};

export const ParsedTaskObjectSchema = TaskObjectSchema.clone()
  .delField("input")
  .addField("input", T.object())
  .delField("output")
  .addField("output", T.object())
  .delField("exception")
  .addField("exception", T.object());
