import T from "@redwit-commons/utils/typecheck";
import {
  deSerializeProjectActivityMeta,
  ProjectActivityMeta,
  ProjectActivityMetaSchema,
  ProjectActivityType,
} from "../api/object/user_log";
import { AccessTime, addAccessTime } from "@redwit-commons/object/access_time";

export type ActivityLogQueryParams = {
  limit: number;
  offset: number;
  beforeAt: string;
  afterAt: string;
};
export type ActivityLogQueryOptions = Partial<ActivityLogQueryParams>;
export const ActivityLogQueryOptionSchema = T.object()
  .addField("limit", T.number(), false)
  .addField("offset", T.number(), false)
  .addField("beforeAt", T.string(), false)
  .addField("afterAt", T.string(), false);

type ActivityLogCore = {
  type: ProjectActivityType;
  ProjectId: string;
  UserId: string;
  FolderId?: string;
  WorkspaceId?: string;
};
export type ActivityLogObject = {
  readonly id: number;
  activity_metadata: ProjectActivityMeta;
} & AccessTime &
  ActivityLogCore;

export type ActivityLogDBObject = {
  readonly id: number;
  project_activity: string;
  chunk_notes?: number;
} & AccessTime &
  ActivityLogCore;

const ActivityLogCoreSchema = T.object()
  .addField(
    "type",
    T.string().withEnum([...Object.values(ProjectActivityType)])
  )
  .addField("ProjectId", T.string())
  .addField("UserId", T.string())
  .addField("FolderId", T.string(), false)
  .addField("WorkspaceId", T.string(), false);
export const ActivityLogObjectSchema = addAccessTime(
  ActivityLogCoreSchema.clone()
    .addField("id", T.number())
    .addField("activity_metadata", ProjectActivityMetaSchema.clone())
);
export const ActivityLogDBObjectSchema = addAccessTime(
  ActivityLogCoreSchema.clone()
    .addField("id", T.number())
    .addField("project_activity", T.string())
    .addField("chunk_notes", T.number(), false)
);

export const extractActivityLogDBObject =
  T.mkObjectExtractor<ActivityLogDBObject>(ActivityLogDBObjectSchema);

export const refineActivityLog = (rdbUserLog: ActivityLogDBObject) => {
  const meta = deSerializeProjectActivityMeta(rdbUserLog.project_activity);
  const refineMeta: ProjectActivityMeta = {
    ...meta,
    chunk_files: rdbUserLog.chunk_notes ?? meta.chunk_files,
  };
  const ret: ActivityLogObject = {
    id: rdbUserLog.id,
    type: rdbUserLog.type,
    ProjectId: rdbUserLog.ProjectId,
    UserId: rdbUserLog.UserId,
    FolderId: rdbUserLog.FolderId,
    WorkspaceId: rdbUserLog.WorkspaceId,
    createdAt: rdbUserLog.createdAt,
    updatedAt: rdbUserLog.updatedAt,
    activity_metadata: refineMeta,
  };

  return {
    ...ret,
    WorkspaceId: ret.WorkspaceId ?? undefined,
  };
};
