import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { theme, service } from "src/styles/themes";

export const useServiceHeaderStyle = makeStyles((MuiTheme: Theme) =>
  createStyles({
    header: {
      width: "100%",
      height: 64,
      backgroundColor: theme.white,
    },
    headerLeft: {
      marginLeft: 40,
    },
    headerRight: {
      height: "100%",
    },
    logoButton: {
      width: 224,
      height: 32,
      padding: 0,
      "&:hover": {
        backgroundColor: "inherit",
      },
    },
    menuItem: {
      marginLeft: 10,
      height: 36,
      padding: "6px 8px",
      whiteSpace: "nowrap",
      fontSize: 16,
      lineHeight: 2,
      letterSpacing: "-0.48",
      fontWeight: `${500} !important` as any,
      color: `${theme.gray_7_text} !important`,
      fill: "currentColor",
    },

    menuOption: {
      position: "relative",
      display: "inline-block",

      "&:hover $menuOptionContent": {
        display: "block",
      },
    },

    menuOptionContent: {
      display: "none",
      width: 170,
      position: "absolute",
      marginLeft: 20,
      zIndex: 1,
      "&:hover": {
        cursor: "pointer",
      },
    },
    creditButton: {
      marginRight: 6,
      height: 40,
      padding: "12px 8px",
      whiteSpace: "nowrap",
      transition: "background .25s, color .25s, border .25s",
      border: "0",
      borderRadius: theme.round_3,
      fontSize: 16,
      lineHeight: 2,
      letterSpacing: "-0.48",
      ...theme.font_regular,
      color: theme.white,
      backgroundColor: service.cool_gray_500,
      fill: "currentColor",
      "@media (hover: hover)": {
        "&:hover": {
          backgroundColor: service.cool_gray_700,
        },
      },
      "&:active": {
        backgroundColor: service.cool_gray_900,
      },
      "&:disabled, &.Mui-disabled": {
        color: service.gray_500,
        backgroundColor: service.gray_200,
      },
    },
    paymentHistoryButton: {
      marginRight: 24,
      height: 40,
      padding: "12px 8px",
      whiteSpace: "nowrap",
      transition: "background .25s, color .25s, border .25s",
      border: "0",
      borderRadius: theme.round_3,
      fontSize: 16,
      lineHeight: 2,
      letterSpacing: "-0.48",
      ...theme.font_regular,
      color: theme.white,
      backgroundColor: service.cool_gray_500,
      fill: "currentColor",
      "@media (hover: hover)": {
        "&:hover": {
          backgroundColor: service.cool_gray_700,
        },
      },
      "&:active": {
        backgroundColor: service.cool_gray_900,
      },
      "&:disabled, &.Mui-disabled": {
        color: service.gray_500,
        backgroundColor: service.gray_200,
      },
    },
    logoutButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.gray_2,
      border: `solid 1px ${service.gray_300}`,
      height: "100%",
      width: 64,
    },
    user_email: {
      color: theme.gray_7_text,
    },

    header_menu_btn: {
      "&&": {
        color: theme.gray_7_text,
        minWidth: 0,
        padding: 0,
        marginRight: 24,
        display: "none",
        visibility: "hidden",

        [MuiTheme.breakpoints.down("md")]: {
          display: "flex",
          visibility: "visible",
        },
      },
    },
  })
);
