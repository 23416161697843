import { createStyles, makeStyles } from "@material-ui/core";
import {
  gds_theme,
  gds_buttons,
  gds_textButtons,
  gds_service,
} from "@ui/goono/styles/theme";

const useStyles = makeStyles(() =>
  createStyles({
    btn_base: {
      whiteSpace: "nowrap",
      borderRadius: gds_theme.round_3,
      ...gds_theme.btn_transition,
      ...gds_buttons.btn_base,
      "&:disabled, &.Mui-disabled": {
        ...gds_textButtons.btn_disabled,
      },
    },

    btn_lg: {
      ...gds_textButtons.size_lg,
      "&::after, &::before": { bottom: 6 },
    },
    btn_md: {
      ...gds_textButtons.size_md,
      "&::after, &::before": { bottom: 5 },
    },
    btn_sm: {
      ...gds_textButtons.size_sm,
      "&::after, &::before": { bottom: 3 },
      "& $icon_base": {
        ...gds_theme.btn_icon_sm,
      },
    },
    underLine: {
      position: "relative",
      paddingBottom: 2,
      background: "none !important",
      "&::before, &::after": {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        height: 2,
        transition: "max-width .25s",
      },
      "&::after": {
        maxWidth: 0,
      },
      "@media (hover: hover)": {
        "&:hover::after": {
          maxWidth: "100%",
        },
      },
    },
    icon_base: {
      ...gds_theme.btn_icon_xl,
      "& img, & svg": {
        maxWidth: "100%",
        maxHeight: "100%",
        fill: "currentColor",
      },
      "& svg *": {
        fill: "inherit",
      },
    },
    icon_start: {
      marginRight: 4,
    },
    icon_end: {
      marginLeft: 4,
    },

    primary: {
      ...gds_textButtons.btn_primary,
      "&::before": { backgroundColor: gds_service.primary_100 },
      "&::after": { backgroundColor: gds_theme.primary },
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_textButtons.btn_primary_hover,
        },
      },
      "&:active": {
        ...gds_textButtons.btn_primary_active,
      },
      "&:disabled, &.Mui-disabled": {
        "&::before": { backgroundColor: gds_theme.gray_5_body },
      },
    },
    black: {
      ...gds_textButtons.btn_black,
      "&::before": { backgroundColor: gds_theme.gray_4 },
      "&::after": { backgroundColor: gds_theme.gray_9_title },
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_textButtons.btn_black_hover,
        },
      },
      "&:disabled, &.Mui-disabled": {
        "&::before": { backgroundColor: gds_theme.gray_5_body },
      },
    },
    gray: {
      ...gds_textButtons.btn_gray,
      "&::before": { backgroundColor: gds_theme.gray_3 },
      "&::after": { backgroundColor: gds_theme.gray_6 },
      "@media (hover: hover)": {
        "&:hover": {
          ...gds_textButtons.btn_gray_hover,
        },
      },
      "&:disabled, &.Mui-disabled": {
        "&::before": { backgroundColor: gds_theme.gray_5_body },
      },
    },
  })
);

export default useStyles;
