import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";
import {
  UserLicenseMapObjectSchema,
  UserLicenseMapObjectCore,
  LicenseRoleTypes,
  LicenseRoleTypesValues,
} from "./user_license_map";
import {
  UserObject,
  validateUserObjectWithLicenseRole,
  UserObjectWithLicenseRoleSchema,
  UserObjectSchema,
  extractUserObject,
} from "./user";

export type UserLogData = {
  latestVisit?: {
    timestamp: string;
    ip?: string;
    os?: string;
    browser?: string;
  };
  downloadCount: number;
  uploadCount: number;
};

export const withUserLogDataSchema = (schema: T) =>
  schema
    .addField(
      "latestVisit",
      T.object()
        .addField("timestamp", T.string())
        .addField("ip", T.string(), false)
        .addField("os", T.string(), false)
        .addField("browser", T.string(), false),
      false
    )
    .addField("downloadCount", T.number())
    .addField("uploadCount", T.number());

export enum LicenseFilterType {
  ALL = "all",
  TRIAL = "trial",
  FREE = "free",
  TEAM = "team",
  ENTERPRISE = "enterprise",
  ENTERPRISE_PLUS = "enterprise_plus",
}
/**@deprecated */
export enum LicensePlan {
  /**@deprecated */
  FREE = "LicensePlan::free",
  STANDARD = "LicensePlan::standard",
  ENTERPRISE = "LicensePlan::enterprise", // 추가 기능을 위한 plan
}

export const getPlanLevel = (plan?: LicensePlan) => {
  switch (plan) {
    case LicensePlan.ENTERPRISE:
      return 2;
    case LicensePlan.STANDARD:
      return 1;
    default:
      return 0;
  }
};
/**@deprecated */
export enum LicenseType {
  PERSONAL = "LicenseType::personal",
  TRIAL = "LicenseType::trial",
  INSTITUTION = "LicenseType::institution",
}

export const getTypeLevel = (Type?: LicenseType) => {
  switch (Type) {
    case LicenseType.INSTITUTION:
      return 3;
    case LicenseType.TRIAL:
      return 2;
    case LicenseType.PERSONAL:
      return 1;
    default:
      return 0;
  }
};

export type LicensePureObjectCoreWOEtc = {
  readonly id: string;
  readonly plan: LicensePlan;
  readonly type: LicenseType;
  readonly seats: number;
  readonly start: string;
  readonly end: string;
  readonly inviteCode: string;
  readonly enableACL?: boolean;
  readonly applyAdmin?: boolean;
  readonly institution_name: string;
  readonly description: string;
};

export type LicensePureObjectCore = LicensePureObjectCoreWOEtc & {
  // TODO: remove this
  etc: string;
};

export const LicensePureObjectCoreWOEtcSchema = withAccessTime()
  .addField("id", T.string())
  .addField(
    "plan",
    T.string().withEnum([
      LicensePlan.FREE,
      LicensePlan.STANDARD,
      LicensePlan.ENTERPRISE,
    ])
  )
  .addField(
    "type",
    T.string().withEnum([
      LicenseType.TRIAL,
      LicenseType.PERSONAL,
      LicenseType.INSTITUTION,
    ])
  )
  .addField("seats", T.number())
  .addField("start", T.string())
  .addField("end", T.string())
  .addField("inviteCode", T.string())
  .addField("enableACL", T.boolean(), false)
  .addField("applyAdmin", T.boolean(), false)
  .addField("institution_name", T.string())
  .addField("description", T.string());

export const LicensePureObjectSchema =
  LicensePureObjectCoreWOEtcSchema.clone().addField("etc", T.string());

export const LicensePureObjectWDescriptionSchema =
  LicensePureObjectCoreWOEtcSchema.clone();

export type LicensePureObject = LicensePureObjectCore & AccessTime;
/**@deprecated */
export type LicensePureObjectWDescription = LicensePureObjectCoreWOEtc &
  AccessTime;
export type LicenseDBObjectCore = {
  readonly Users: Array<
    UserObject & { User_License_Map: UserLicenseMapObjectCore }
  >;
} & LicensePureObjectCore;

export type RefinedLicenseObjectCore = LicensePureObjectWDescription & {
  readonly Users: Array<UserObject & { role: LicenseRoleTypes }>;
};

export type LicenseDBObject = LicenseDBObjectCore & AccessTime;
export type LicenseObject = RefinedLicenseObjectCore & AccessTime;
export type LicenseObjectWithUserLog = LicensePureObjectWDescription & {
  readonly Users: Array<UserObject & { role: LicenseRoleTypes } & UserLogData>;
};
/**@deprecated */
export const refineLicenseObject = (
  rdbLicense: LicenseDBObject
): LicenseObject => {
  if (rdbLicense.Users.length < 1) {
    return {
      ...rdbLicense,
      Users: [],
    };
  }
  const Users = rdbLicense.Users.filter(
    (user) => user.deactivated === false
  ).map((user) => {
    const userObject = extractUserObject(user);
    const role = user.User_License_Map.role;
    const userObjWithRole = validateUserObjectWithLicenseRole({
      ...userObject,
      role,
    });
    return userObjWithRole;
  });
  return {
    ...rdbLicense,
    Users,
  };
};

export const LicenseDBObjectSchema = LicensePureObjectSchema.clone().addField(
  "Users",
  T.array(
    UserObjectSchema.clone().addField(
      "User_License_Map",
      UserLicenseMapObjectSchema.clone()
    )
  )
);

export const LicenseObjectSchema =
  LicensePureObjectWDescriptionSchema.clone().addField(
    "Users",
    T.array(UserObjectWithLicenseRoleSchema.clone())
  );

export const LicenseObjectWithUserLogSchema =
  LicensePureObjectWDescriptionSchema.clone().addField(
    "Users",
    T.array(withUserLogDataSchema(UserObjectWithLicenseRoleSchema.clone()))
  );
export const validateLicenseObjectWithUserLog =
  T.mkValidator<LicenseObjectWithUserLog>(LicenseObjectWithUserLogSchema);

export const extractLicensePureObject = T.mkObjectExtractor<LicensePureObject>(
  LicensePureObjectSchema
);
export const validateLicenseObject =
  T.mkValidator<LicenseObject>(LicenseObjectSchema);
/**@deprecated */
export const extractLicenseDBObject = T.mkObjectExtractor<LicenseDBObject>(
  LicenseDBObjectSchema
);

export type UserDBObjectWithLicenseCore = UserObject & {
  readonly Licenses: Array<
    LicensePureObject & { User_License_Map: UserLicenseMapObjectCore }
  >;
};
export type UserDBObjectWithLicense = UserDBObjectWithLicenseCore & AccessTime;
export type RefinedUserWithLicense = UserObject & {
  readonly Licenses: Array<
    LicensePureObjectWDescription & { role: LicenseRoleTypes }
  >;
};
export const UserDBObjectWithLicenseSchema = UserObjectSchema.clone().addField(
  "Licenses",
  T.array(
    LicensePureObjectSchema.clone().addField(
      "User_License_Map",
      UserLicenseMapObjectSchema.clone()
    )
  )
);
export const RefinedUserWithLicenseSchema = UserObjectSchema.clone().addField(
  "Licenses",
  T.array(
    LicensePureObjectWDescriptionSchema.clone().addField(
      "role",
      T.string().withEnum(LicenseRoleTypesValues)
    )
  )
);

export type UserLicenseWithUserLog = RefinedUserWithLicense & UserLogData;
export const UserLicenseWithUserLogSchema = withUserLogDataSchema(
  RefinedUserWithLicenseSchema.clone()
);
/**@deprecated */
export const extractUserObjectWithLicense =
  T.mkObjectExtractor<UserDBObjectWithLicense>(UserDBObjectWithLicenseSchema);
/**@deprecated */
export const refineUserWithLicense = (
  rdbUser: UserDBObjectWithLicense
): RefinedUserWithLicense => {
  const Licenses = rdbUser.Licenses.map((lics) => {
    const role = lics.User_License_Map.role;
    const licenseWithRole: LicensePureObjectWDescription & {
      role: LicenseRoleTypes;
    } = {
      ...lics,
      role,
    };
    return licenseWithRole;
  });
  const userObject = extractUserObject(rdbUser);
  return {
    ...userObject,
    Licenses,
  };
};
/**@deprecated */
export type CoreAdminLicenseObject = {
  admin: {
    name: string;
    email: string;
  };
  totalAuthNote: number;
  id: string;
  name: string;
  period: {
    startAt: string;
    endAt: string;
  };
  members: Array<{
    id: string;
    name: string;
    role: LicenseRoleTypes;
    auths: Array<{ createdAt: string }>;
  }>;
};
/**@deprecated */
export const CoreAdminLicenseObjectSchema = T.object()
  .addField(
    "admin",
    T.object().addField("name", T.string()).addField("email", T.string())
  )
  .addField("totalAuthNote", T.number())
  .addField("id", T.string())
  .addField("name", T.string())
  .addField(
    "period",
    T.object().addField("startAt", T.string()).addField("endAt", T.string())
  )
  .addField(
    "members",
    T.array(
      T.object()
        .addField("id", T.string())
        .addField("name", T.string())
        .addField(
          "role",
          T.string().withEnum([...Object.values(LicenseRoleTypes)])
        )
        .addField(
          "auths",
          T.array(T.object().addField("createdAt", T.string()))
        )
    )
  );

export enum LicenseSharingErrorCase {
  /** 만료된 링크 */
  EXPIRED_LINK = "LicenseSharingStatus::EXPIRED_LINK",
  /** 라이센스 인원 초과 */
  OVERCAPACITY_LICENSE = "LicenseSharingStatus::OVERCAPACITY",
  /** 이미 라이센스가 있음 */
  ALEADY_HAVE_LICENSE = "LicenseSharingStatus::ALEADY_HAVE_LICENSE",
  /** 사라진 라이센스 */
  UNKNOWN_LICENSE = "LicenseSharingStatus::UNKNOWN_LICENSE",
  /** 무료 체험 사용 불가 */
  ALREADY_USE_TRIAL = "LicenseSharingStatus::ALREADY_USE_TRIAL",
  /** 그 외 실패 */
  FAIL = "LicenseSharingStatus::FAIL",
}
