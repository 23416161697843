import {
  ActivityLogObject,
  ActivityLogObjectSchema,
} from "../../v3/activityLog";
import T from "@redwit-commons/utils/typecheck";
import { UserObject, UserObjectSchema } from "../object/user";
import { UserLogObject, UserLogObjectSchema } from "../object/user_log";
import {
  GoonoArrayResponse,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "./common";

export type OGetUserLogs = GoonoArrayResponse<UserLogObject>;

export const OGetUserLogsSchema = mkArrayResultsSchema(UserLogObjectSchema);

export const validateOGetUserLogs =
  T.mkValidator<OGetUserLogs>(OGetUserLogsSchema);

export type OCreateLog = GoonoResponse<string>;
// for enterprise Workspaced user
export type OGetACLLogs = GoonoArrayResponse<{
  User: UserObject;
  Logs: UserLogObject[];
}>;
export type OGetProjectActivity = {
  total: number;
  results: ActivityLogObject[];
};
export type RecentFileUploadHistoryObject = {
  FolderId: null | string;
  NoteId: string;
  ProjectId: string;
  WorkspaceId: string;
  createdAt: string;
  fileExtension: string;
  fileName: string;
  userName: string;
};
export type OGetRecentFileUploadHistory = Array<RecentFileUploadHistoryObject>;

export const OGetRecentFileUploadHistorySchema = T.array(
  T.object()
    .addField("FolderId", T.string(), false)
    .addField("NoteId", T.string())
    .addField("ProjectId", T.string())
    .addField("WorkspaceId", T.string())
    .addField("createdAt", T.string())
    .addField("fileExtension", T.string())
    .addField("fileName", T.string())
    .addField("userName", T.string())
);
export const OCreateLogSchema = mkResponseSchema(T.string());
export const OGetACLLogsSchema = mkArrayResultsSchema(
  T.object()
    .addField("User", UserObjectSchema.clone())
    .addField("Logs", T.array(UserLogObjectSchema.clone()))
);
export const OGetProjectActivitySchema = T.object()
  .addField("total", T.number())
  .addField("results", T.array(ActivityLogObjectSchema));

export const validateOCreateLog = T.mkValidator<OCreateLog>(OCreateLogSchema);
export const validateOGetACLLogs =
  T.mkValidator<OGetACLLogs>(OGetACLLogsSchema);
export const validateOGetProjectActivity = T.mkValidator<OGetProjectActivity>(
  OGetProjectActivitySchema
);
export const validateOGetRecentFileUploadHistory = T.mkValidator<
  RecentFileUploadHistoryObject[]
>(OGetRecentFileUploadHistorySchema);
