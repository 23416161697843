import { doAPIGetRequest, doAPIPostRequest } from "./api";
import { ICreateUserLog } from "@goono-commons/api/request/user_log";
import {
  validateOGetProjectActivity,
  validateOGetRecentFileUploadHistory,
} from "@goono-commons/api/response/user_log";
import { IGetProjectActivity } from "@goono-commons/v3/request/activityLog";
import { IGetAdminDashboardLogs } from "@goono-commons/v3/request/adminDashboard";
import {
  validateOGetAdminNoteLogs,
  validateOGetAdminTagLogs,
  validateOGetAdminUploaderLog,
} from "@goono-commons/v3/response/adminDashboard";

export const getOrganizationAdminNoteLogs = async (
  token: string,
  workspaceId: string,
  args: IGetAdminDashboardLogs
) => {
  const ret = await doAPIGetRequest(
    `/organizations/researcher/note/logs/${workspaceId}`,
    token,
    args
  );
  return validateOGetAdminNoteLogs(ret);
};

export const getOrganizationAdminUploaderLog = async (
  token: string,
  workspaceId: string,
  args: IGetAdminDashboardLogs
) => {
  const ret = await doAPIGetRequest(
    `/organizations/count/uploaders/${workspaceId}`,
    token,
    args
  );
  return validateOGetAdminUploaderLog(ret);
};

export const getOrganizationAdminTagLogs = async (
  token: string,
  workspaceId: string,
  args: IGetAdminDashboardLogs
) => {
  const ret = await doAPIGetRequest(
    `/organizations/tag/rank/${workspaceId}`,
    token,
    args
  );
  return validateOGetAdminTagLogs(ret);
};

export const getAdminNoteLogs = async (
  token: string,
  workspaceId: string,
  args: IGetAdminDashboardLogs
) => {
  const ret = await doAPIGetRequest(
    `/projects/manager/researcher/note/logs/${workspaceId}`,
    token,
    args
  );
  return validateOGetAdminNoteLogs(ret);
};

export const getAdminUploaderLog = async (
  token: string,
  workspaceId: string,
  args: IGetAdminDashboardLogs
) => {
  const ret = await doAPIGetRequest(
    `/projects/manager/count/uploaders/${workspaceId}`,
    token,
    args
  );
  return validateOGetAdminUploaderLog(ret);
};

export const getAdminTagLogs = async (
  token: string,
  workspaceId: string,
  args: IGetAdminDashboardLogs
) => {
  const ret = await doAPIGetRequest(
    `/projects/manager/tag/rank/${workspaceId}`,
    token,
    args
  );
  return validateOGetAdminTagLogs(ret);
};

export const createUserLog = async (token: string, args: ICreateUserLog) => {
  await doAPIPostRequest("/user_log", token, args);
  return;
};

export const getProjectActivity = async (
  token: string,
  projectId: string,
  workspaceId: string,
  args: IGetProjectActivity
) => {
  const ret = await doAPIGetRequest(
    `/activity/list/${projectId}/${workspaceId}`,
    token,
    args
  );
  return validateOGetProjectActivity(ret);
};

export const getActivitiesInProject = async (
  token: string,
  workspaceId: string,
  args: IGetProjectActivity
) => {
  const ret = await doAPIGetRequest(
    `/activity/list/user/projects/${workspaceId}`,
    token,
    args
  );
  return validateOGetProjectActivity(ret);
};

export const getRecentFileUploadHistory = async (
  token: string,
  workspaceId: string
) => {
  const ret = await doAPIGetRequest(
    `/note/upload/history/${workspaceId}`,
    token
  );
  return validateOGetRecentFileUploadHistory(ret);
};

export default {
  createUserLog,
  getProjectActivity,
  getActivitiesInProject,
};
