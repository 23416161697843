import { useQuery, useMutation } from "react-query";
import { ICreateUserLog } from "@goono-commons/api/request/user_log";
import { useLoginUserInfo } from "@utils/hooks/service/useLoginUserInfo";
import { createUserLog } from "@goono-react-commons/services/user_log";
import { getAllGraphs } from "@goono-react-commons/services/admin";

export enum LogQueryKey {
  getInsightGraph = "LogQueryKey::getInsightGraph",
}

export const useGetInsightGraphQuery = () => {
  const userInfo = useLoginUserInfo();

  return useQuery(
    [LogQueryKey.getInsightGraph],
    async () => {
      const graphRet = await getAllGraphs(userInfo.token);
      const graphs = graphRet.response;

      return { graphs };
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useCreateLogMutation = () => {
  const userInfo = useLoginUserInfo();

  return useMutation(async (props: ICreateUserLog) => {
    await createUserLog(userInfo.token, props);
  });
};
