import T from "@redwit-commons/utils/typecheck";
import { WorkspaceMemberRoleSchema, WorkspaceRoleKind } from "./workspace";

export type ResearcherUploadInfo = {
  id: string;
  name: string;
  upload_files: number;
  role: WorkspaceRoleKind;
  deactivated: boolean | undefined;
};
export type FileUploadActivitySummary = {
  /**
   * 추가 된 전체 파일 갯수
   */
  total_files: number;
  /**
   * 연구원 리스트
   */
  researcher_list: Array<ResearcherUploadInfo>;
};

export const ResearcherUploadInfoSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("upload_files", T.number())
  .addField("role", WorkspaceMemberRoleSchema.clone())
  .addField("deactivated", T.boolean(), false);

export const FileUploadActivitySummarySchema = T.object()
  .addField("total_files", T.number())
  .addField("researcher_list", T.array(ResearcherUploadInfoSchema.clone()));

export const validateFileUploadActivitySummary =
  T.mkValidator<FileUploadActivitySummary>(FileUploadActivitySummarySchema);

export type ResearcherDetailUploadLog = {
  userId: string;
  createdAt: string;
  upload_files: number | undefined;
};
export type ResearcherUploadHistory = {
  total: number;
  results: Array<ResearcherDetailUploadLog>;
};

export const ResearcherDetailUploadLogSchema = T.object()
  .addField("userId", T.string())
  .addField("createdAt", T.string())
  .addField("upload_files", T.number(), false);

export const ResearcherUploadHistorySchema = T.object()
  .addField("total", T.number())
  .addField("results", T.array(ResearcherDetailUploadLogSchema));

export const validateResearcherUploadHistory =
  T.mkValidator<ResearcherUploadHistory>(ResearcherUploadHistorySchema);

export type ResearcherNoteLog = {
  id: string;
  createdAt: string;
  UserId: string;
  ProjectId: string;
};
export const ResearcherNoteLogSchema = T.object()
  .addField("id", T.string())
  .addField("UserId", T.string())
  .addField("ProjectId", T.string())
  .addField("createdAt", T.string());

export type MostUpdatedProject = {
  id: string;
  name: string;
};
export const MostUpdatedProjectSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string());

export type ActiveWorkspaceMemberLog = {
  total: number;
  count: number;
};
export const ActiveWorkspaceMemberLogSchema = T.object()
  .addField("total", T.number())
  .addField("count", T.number());

export type TagLog = {
  tag: string;
  rate: number;
};
export const TagLogSchema = T.object()
  .addField("tag", T.string())
  .addField("rate", T.number());
