import { omit } from "lodash";
import {
  LoginUserInfo,
  TokenStateStatus,
} from "../../../redux/store/reducers/token";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";

export const useLoginUserInfo = (): LoginUserInfo => {
  const tokenState = useSelector((state: RootState) => state.token).state;

  if (tokenState.status !== TokenStateStatus.SUCCESS) throw Error;

  return {
    ...omit(tokenState, "status"),
  };
};
