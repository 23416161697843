import T from "@redwit-commons/utils/typecheck";
import { WorkspaceMemberSummary } from "../../../v3/goonoAdmin";
import {
  WorkspaceMemberRoleSchema,
  WorkspacePlan,
} from "../../../v3/workspace";
import { IpfsData, IpfsDataSchema } from "../ipfs";

/**
 * ADMIN Task 의 세부 stage
 */
export enum AdminTaskStage {
  /**
   * Task manager 에 의해서 queueing 됨
   */
  QUEUED = "TaskStage::ADMIN::QUEUED",
  /**
   * Task manager 에 의해서 쿼리 실행중
   */
  PROCESSING = "TaskStage::ADMIN::PROCESSING",
}

/**
 * 전체를 100분률로 나눴을 때 각 Stage 가 얼마나 차지하는지를 나타냄.
 * 나중에 Progressbar 구현할 때 사용
 */
export const stageInfo: Array<[string, number]> = [
  [AdminTaskStage.QUEUED, 20],
  [AdminTaskStage.PROCESSING, 80],
];

export const AdminTaskKind = "AdminTask";
/**
 * AdminTask 의 이름
 */
export enum AdminTaskSubKind {
  CHANGE_USER = "AdminTaskSubKind::CHANGE_USER",
  DELETE_USER = "AdminTaskSubKind::DELETE_USER",
  DELETE_SELF = "AdminTaskSubKind::DELETE_SELF",
  BULK_RENEW_WORKSPACE = "AdminTaskSubKind::BULK_RENEW_WORKSPACE",
  BULK_SET_WORKSPACE_MEMBER = "AdminTaskSubKind::BULK_SET_WORKSPACE_MEMBER",
}

/**
 * AdminTask 의 input 에 들어갈 정보
 */
export type AdminTaskInput =
  | AdminDeleteUserInput
  | AdminChangeUserInput
  | AdminDeleteSelfInput
  | AdminBulkRenewWorkspaceInput
  | AdminBulkSetWorkspaceMemberInput;

export type AdminDeleteUserInput = {
  task_kind: AdminTaskSubKind.DELETE_USER;
  UserId: string;
  platform: string;
  email: string;
  reason: string;
  attachment: IpfsData;
};
export type AdminChangeUserInput = {
  task_kind: AdminTaskSubKind.CHANGE_USER;
  UserId: string;
  platform: string;
  email: string;
  reason: string;
  attachment: IpfsData;
};
export type AdminDeleteSelfInput = {
  task_kind: AdminTaskSubKind.DELETE_SELF;
  UserId: string;
  platform: string;
  email: string;
  reason?: string;
  attachment?: IpfsData;
};
export type AdminBulkRenewWorkspaceInput = {
  task_kind: AdminTaskSubKind.BULK_RENEW_WORKSPACE;
  requestUserId: string;
  workspaceIds: string[];
  workspacePlan: Partial<WorkspacePlan>;
};
export type AdminBulkSetWorkspaceMemberInput = {
  task_kind: AdminTaskSubKind.BULK_SET_WORKSPACE_MEMBER;
  workspaceIds: string[];
  members: Array<WorkspaceMemberSummary>;
};

export const AdminDeleteUserSchema = T.object()
  .addField("task_kind", T.string().withEnum([AdminTaskSubKind.DELETE_USER]))
  .addField("UserId", T.string())
  .addField("platform", T.string())
  .addField("email", T.string())
  .addField("reason", T.string())
  .addField("attachment", IpfsDataSchema);
export const AdminChangeUserSchema = T.object()
  .addField("task_kind", T.string().withEnum([AdminTaskSubKind.CHANGE_USER]))
  .addField("UserId", T.string())
  .addField("platform", T.string())
  .addField("email", T.string())
  .addField("reason", T.string())
  .addField("attachment", IpfsDataSchema);
export const AdminDeleteSelfSchema = T.object()
  .addField("task_kind", T.string().withEnum([AdminTaskSubKind.DELETE_SELF]))
  .addField("UserId", T.string())
  .addField("platform", T.string())
  .addField("email", T.string())
  .addField("reason", T.string(), false)
  .addField("attachment", IpfsDataSchema, false);
const WorkspacePlanSchema = T.object()
  .addField("type", T.string(), false)
  .addField("seats", T.number(), false)
  .addField(
    "period",
    T.object().addField("startAt", T.string()).addField("endAt", T.string()),
    false
  )
  .addField("price", T.string(), false)
  .addField("name", T.string(), false)
  .addField("description", T.string(), false)
  .addField(
    "aclOption",
    T.object()
      .addField("enableACL", T.boolean(), false)
      .addField("applyAdmin", T.boolean(), false),
    false
  );
export const AdminBulkRenewWorkspaceSchema = T.object()
  .addField(
    "task_kind",
    T.string().withEnum([AdminTaskSubKind.BULK_RENEW_WORKSPACE])
  )
  .addField("requestUserId", T.string())
  .addField("workspaceIds", T.array(T.string()))
  .addField("workspacePlan", WorkspacePlanSchema.clone());
export const AdminBulkSetWorkspaceMemberSchema = T.object()
  .addField(
    "task_kind",
    T.string().withEnum([AdminTaskSubKind.BULK_SET_WORKSPACE_MEMBER])
  )
  .addField("workspaceIds", T.array(T.string()))
  .addField(
    "members",
    T.array(
      T.object()
        .addField("id", T.string())
        .addField("role", WorkspaceMemberRoleSchema.clone())
    )
  );

export const AdminTaskInputSchema = T.anyOf([
  AdminDeleteUserSchema,
  AdminChangeUserSchema,
  AdminDeleteSelfSchema,
  AdminBulkRenewWorkspaceSchema,
  AdminBulkSetWorkspaceMemberSchema,
]);

export const validateAdminTaskInput =
  T.mkValidator<AdminTaskInput>(AdminTaskInputSchema);

export enum AdminBulkTaskFailReason {
  MEMBER_OVER_SEATS = "AdminBulkTaskFailReason::MEMBER_OVER_SEATS",
  OWNER_OPERATION = "AdminBulkTaskFailReason::OWNER_OPERATION",
  PLAN_NOT_FOUND = "AdminBulkTaskFailReason::PLAN_NOT_FOUND",
  UNKNOWN = "AdminBulkTaskFailReason::UNKNOWN",
}
export type FailedAdminBulkTaskData = {
  workspaceId: string;
  workspaceName: string;
  reason: AdminBulkTaskFailReason;
};

export type AdminTaskOutput = {
  processedAt: string;
  failedBulkJobs?: FailedAdminBulkTaskData[];
};

export const AdminTaskOutputSchema = T.object()
  .addField("processedAt", T.string())
  .addField(
    "failedBulkJobs",
    T.array(
      T.object()
        .addField("workspaceId", T.string())
        .addField("workspaceName", T.string())
        .addField(
          "reason",
          T.string().withEnum(Object.values(AdminBulkTaskFailReason))
        )
    ),
    false
  );

export const validateAdminTaskOutput = T.mkValidator<AdminTaskOutput>(
  AdminTaskOutputSchema
);
