import { combineReducers } from "redux";
import token from "./token";
import modal from "./modal";
import snackbar from "./snackbar";

/**
 * root reducer
 */
const rootReducer = combineReducers({
  token,
  modal,
  snackbar,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
