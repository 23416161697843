import { makeStyles } from "@material-ui/core";
import { service, theme } from "src/styles/themes";

const useStyles = makeStyles(() => ({
  wrapper: {
    borderRadius: 10,
    boxShadow: "0 4px 12px 0 rgba(33, 37, 41, 0.19)",
    border: `solid 1px ${service.gray_500}`,
    backgroundColor: service.white,
  },
  option: {
    width: "100%",
    padding: "8px 16px",
    "&:hover": {
      "& p": {
        color: `${theme.primary} !important`,
        ...theme.font_bold,
      },
    },
  },
}));

export default useStyles;
