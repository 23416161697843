import React from "react";
import { Button, ButtonBase } from "@material-ui/core";
import { GOONOAdminLogType } from "@goono-commons/api/object/user_log";
import { ReactComponent as GoonoLogoIcon } from "@assets/images/logo.svg";
import { ReactComponent as LogoutIcon } from "@assets/images/logout.svg";
import { ReactComponent as PersonIcon } from "@assets/images/person.svg";
import ScreenURL from "src/routes/route_list";
import { useServiceHeaderStyle } from "../styles";

import MenuIcon from "@material-ui/icons/Menu";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDownRounded";
import HistoryIcon from "@material-ui/icons/HistoryRounded";
import AddIcon from "@material-ui/icons/Add";

import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import { Outlet, useNavigate } from "react-router-dom";
import { useCreateLogMutation } from "@react-query/api/log";
import { useLogoutMutation } from "@react-query/api/auth";
import { useLoginUserInfo } from "@utils/hooks/service/useLoginUserInfo";
import HeaderMenuOption from "./HeaderMenuOption";
import TextButton from "@ui/goono/components/buttons/TextButton";
import SolidButton from "@ui/goono/components/buttons/SolidButton";
import useDebounceWindowWidth from "@utils/hooks/pure/useDebounceWindowWidth";

type Props = {
  sidebarOpen?: boolean;
  setSidebarOpen?: (b: boolean) => void;
};

export default function ServiceHeader(props: Props) {
  const classes = useServiceHeaderStyle();
  const userInfo = useLoginUserInfo();
  const navigate = useNavigate();

  const windowWidth = useDebounceWindowWidth();
  const isMobileMode = windowWidth < 1280;

  const createLogsMutation = useCreateLogMutation();
  const logoutMutation = useLogoutMutation();

  const onReplace = (url: string) => {
    navigate(url, { replace: true });
  };

  const onMakeLog = (type: GOONOAdminLogType) => {
    createLogsMutation.mutate({
      userType: "goono-admin",
      type,
      UserId: userInfo.id,
      adminEmail: userInfo.email,
    });
  };

  const renderHeaderLogo = () => {
    return (
      <ButtonBase
        disableRipple
        onClick={() => onReplace(ScreenURL.MANAGE_USER)}
        className={classes.logoButton}
      >
        <GoonoLogoIcon />
      </ButtonBase>
    );
  };

  const renderButtonMenu = (params: {
    title: string;
    optionTitle: { first: string; second: string };
    firstPageURL: string;
    secondPageURL: string;
  }) => {
    return (
      <div className={classes.menuOption}>
        <SolidButton
          buttonType="white"
          endIcon={<ArrowDropDownIcon />}
          onClick={() => onReplace(params.firstPageURL)}
          className={classes.menuItem}
        >
          {params.title}
        </SolidButton>
        <HeaderMenuOption
          navigateFirstPage={() => onReplace(params.firstPageURL)}
          navigateSecondPage={() => onReplace(params.secondPageURL)}
          firstOptionTitle={params.optionTitle.first}
          secondOptionTitle={params.optionTitle.second}
          className={classes.menuOptionContent}
        />
      </div>
    );
  };

  const renderHeaderMenuList = () => {
    return (
      <Flex container style={{ marginLeft: 30 }} alignItems="center">
        {renderButtonMenu({
          title: "사용자 관리",
          firstPageURL: ScreenURL.MANAGE_USER,
          secondPageURL: ScreenURL.MANAGE_WORKSPACE,
          optionTitle: {
            first: "전체 사용자 보기",
            second: "전체 워크스페이스 보기",
          },
        })}
        {renderButtonMenu({
          title: "조직 관리",
          firstPageURL: ScreenURL.MANAGE_ORGANIZATION,
          secondPageURL: ScreenURL.MANAGE_ORGANIZATION_ADMIN,
          optionTitle: {
            first: "전체 조직 보기",
            second: "전체 조직 관리자 보기",
          },
        })}
        <TextButton
          className={classes.menuItem}
          onClick={() => {
            onReplace(ScreenURL.DASHBOARD);
            onMakeLog(GOONOAdminLogType.VIEW_DASHBOARD);
          }}
        >
          구노 대시보드
        </TextButton>
      </Flex>
    );
  };

  const renderHeaderCreditButton = () => {
    return (
      <SolidButton
        buttonSize="lg"
        startIcon={<AddIcon />}
        onClick={() => onReplace(ScreenURL.PAYMENT_WINDOW_CREATION)}
      >
        결제창 생성
      </SolidButton>
    );
  };

  const renderPaymentHistoryButton = () => {
    return (
      <SolidButton
        startIcon={<HistoryIcon />}
        onClick={() => onReplace(ScreenURL.PAYMENT_WINDOW_CREATION_HISTORY)}
      >
        결제창 생성 내역
      </SolidButton>
    );
  };

  const renderHeaderUserEmail = () => {
    return (
      <Flex container alignItems={`center`} className={classes.user_email}>
        <PersonIcon style={{ marginRight: 4 }} />
        <GoonoTypography type="ele1_m">{userInfo.email}</GoonoTypography>
      </Flex>
    );
  };

  const renderHeaderLogoutButton = () => {
    return (
      <ButtonBase
        disableRipple
        onClick={async () => await logoutMutation.mutateAsync()}
        className={classes.logoutButton}
      >
        <LogoutIcon />
      </ButtonBase>
    );
  };

  const renderHeaderLeftItems = () => {
    return (
      <Flex
        container
        item
        flexDirection="row"
        alignItems="center"
        className={classes.headerLeft}
      >
        {renderHeaderLogo()}
        {!isMobileMode && renderHeaderMenuList()}
      </Flex>
    );
  };

  const renderHeaderRightItems = () => {
    return (
      <Flex
        container
        item
        flexDirection="row"
        alignItems="center"
        className={classes.headerRight}
      >
        <Flex
          container
          item
          alignItems={`center`}
          gap="10px"
          style={{ marginRight: 50 }}
        >
          {!isMobileMode && renderHeaderCreditButton()}
          {!isMobileMode && renderPaymentHistoryButton()}
          {!isMobileMode && renderHeaderUserEmail()}
        </Flex>
        <ControlMenuButton {...props} />
        {renderHeaderLogoutButton()}
      </Flex>
    );
  };

  return (
    <>
      <Flex
        container
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.header}
      >
        {renderHeaderLeftItems()}
        {renderHeaderRightItems()}
      </Flex>
      <Outlet />
    </>
  );
}

function ControlMenuButton(props: {
  sidebarOpen?: boolean;
  setSidebarOpen?: (b: boolean) => void;
}) {
  const classes = useServiceHeaderStyle();

  return (
    <Button
      className={classes.header_menu_btn}
      onClick={() =>
        props.setSidebarOpen && props.setSidebarOpen(!props.sidebarOpen)
      }
    >
      {props.sidebarOpen ? <CloseRoundedIcon /> : <MenuIcon />}
    </Button>
  );
}
