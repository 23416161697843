import T from "@redwit-commons/utils/typecheck";
import {
  AdminProjectExcelResponse,
  AdminProjectExcelResponseSchema,
  AdminProjectSummaryDTO,
  AdminProjectSummaryDTOSchema,
  ProjectDTO,
  ProjectDTOSchema,
  ProjectDTOSummarySchema,
  ProjectSummaryDTO,
} from "../project";

export type ONewCreateProject = { id: string };
export const ONewCreateProjectSchema = T.object().addField("id", T.string());
export const validateONewCreateProject = T.mkValidator<ONewCreateProject>(
  ONewCreateProjectSchema
);

export type OGetAdminProjectSummaryDTO = Array<AdminProjectSummaryDTO>;
export const OGetAdminProjectSummaryDTOSchema = T.array(
  AdminProjectSummaryDTOSchema.clone()
);
export const validateOGetAdminProjectSummaryDTO =
  T.mkValidator<OGetAdminProjectSummaryDTO>(OGetAdminProjectSummaryDTOSchema);

export type OGetAdminUserOwnerProjectSummaryDTOList = Array<ProjectSummaryDTO>;
export const OGetAdminUserOwnerProjectSummaryDTOListSchema = T.array(
  ProjectDTOSummarySchema.clone()
);
export const validateOGetAdminUserOwnerProjectSummaryDTOList =
  T.mkValidator<OGetAdminUserOwnerProjectSummaryDTOList>(
    OGetAdminUserOwnerProjectSummaryDTOListSchema
  );

export type OGetPinnedProjectList = Array<ProjectDTO>;
export const OGetPinnedProjectListSchema = T.array(ProjectDTOSchema.clone());
export const validateOGetPinnedProjectList =
  T.mkValidator<OGetPinnedProjectList>(OGetPinnedProjectListSchema);

export type OGetProjectTotalPerCategory = {
  total: number;
  currentLicense: number;
  externalLicense: number;
  expiredLicense: number;
};
export const OGetProjectTotalPerCategorySchema = T.object()
  .addField("total", T.number())
  .addField("currentLicense", T.number())
  .addField("externalLicense", T.number())
  .addField("expiredLicense", T.number());
export const validateOGetProjectTotalPerCategory =
  T.mkValidator<OGetProjectTotalPerCategory>(OGetProjectTotalPerCategorySchema);

export const validateOGetAllAdminExcelProjects =
  T.mkValidator<AdminProjectExcelResponse>(AdminProjectExcelResponseSchema);
