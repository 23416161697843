import { doAPIPostRequest, doAPIGetRequest } from "./api";
import {
  ILogin,
  IRegister,
  IProfileUpdate,
  ILoginWithEmail,
  IRegisterWithEmail,
  ICheckRegistered,
  IAuthenticationPW,
  IUpdatePW,
  IUpdateLoginUserPW,
  IUserModify,
} from "@goono-commons/api/request/user";
import {
  OLogin,
  validateOLogin,
  ORegister,
  validateORegister,
  OProfile,
  validateOProfile,
  validateOProfileUpdate,
  ORegisterWithEmail,
  validateOLoginWithEmail,
  OLoginWithEmail,
  validateORegisterWithEmail,
  validateOCheckRegistered,
  OCheckRegistered,
  validateOAuthenticationPW,
  validateOUpdatePW,
  OUpdatePW,
  OAuthenticationPW,
  OUserModify,
  validateOUserModify,
} from "@goono-commons/api/response/user";

export enum goonoLoginType {
  SNS = "ILoginType::SNS",
  EMAIL = "ILoginType::EMAIL",
}

export type goonoLoginResult = {
  needRegister: boolean;
  token: string;
  failedCount?: number;
  errorMessage?: string;
  platform?: string;
};

export type GoonoLoginArgs =
  | {
      type: goonoLoginType.EMAIL;
      args: ILoginWithEmail;
    }
  | {
      type: goonoLoginType.SNS;
      args: ILogin;
    };

export const goonoEmailCheckRegister = async (
  args: ICheckRegistered
): Promise<OCheckRegistered> => {
  const ret = await doAPIPostRequest("user/register/check", undefined, args);
  return validateOCheckRegistered(ret);
};

export const goonoLoginWithSNS = async (args: ILogin): Promise<OLogin> => {
  const ret = await doAPIPostRequest("user/login", undefined, args);
  return validateOLogin(ret);
};

export const goonoLoginWithEmail = async (
  args: ILoginWithEmail
): Promise<OLoginWithEmail> => {
  const ret = await doAPIPostRequest("user/login/email", undefined, args);
  return validateOLoginWithEmail(ret);
};

export const goonoLogin = async (
  args: GoonoLoginArgs
): Promise<goonoLoginResult> => {
  switch (args.type) {
    case goonoLoginType.EMAIL: {
      const ret = await goonoLoginWithEmail(args.args);
      return ret.response;
    }
    default: {
      const ret = await goonoLoginWithSNS(args.args);
      return ret.response;
    }
  }
};

export type GoonoRegisterArgs =
  | {
      type: goonoLoginType.EMAIL;
      args: IRegisterWithEmail;
    }
  | {
      type: goonoLoginType.SNS;
      args: IRegister;
    };

export type goonoRegisterResult = {
  token: string;
};

export const goonoRegisterWithSNS = async (
  args: IRegister
): Promise<ORegister> => {
  const ret = await doAPIPostRequest("user/register", undefined, args);
  return validateORegister(ret);
};

export const goonoReigsterWithEmail = async (
  args: IRegisterWithEmail
): Promise<ORegisterWithEmail> => {
  const ret = await doAPIPostRequest("user/register/email", undefined, args);
  return validateORegisterWithEmail(ret);
};

/**
 * Goono 서버 회원가입
 * @param args IRegsiter
 * @returns Promise<goonoRegisterResult>
 */
export const goonoRegister = async (
  args: GoonoRegisterArgs
): Promise<goonoRegisterResult> => {
  switch (args.type) {
    case goonoLoginType.EMAIL: {
      const ret = await goonoReigsterWithEmail(args.args);
      return ret.response;
    }
    default: {
      const ret = await goonoRegisterWithSNS(args.args);
      return ret.response;
    }
  }
};

export const goonoProfile = async (goonoToken: string): Promise<OProfile> => {
  const ret = await doAPIGetRequest("user/profile", goonoToken);
  return validateOProfile(ret);
};

export const goonoProfileUpdate = async (
  goonoToken: string,
  args: IProfileUpdate
): Promise<OProfile> => {
  const ret = await doAPIPostRequest("user/profile/update", goonoToken, args);
  return validateOProfileUpdate(ret);
};

export const goonoAuthenticationPW = async (
  args: IAuthenticationPW
): Promise<OAuthenticationPW> => {
  const ret = validateOAuthenticationPW(
    await doAPIPostRequest("user/authentication/password", undefined, args)
  );
  return ret;
};

export const goonoUpdatePW = async (args: IUpdatePW): Promise<OUpdatePW> => {
  const ret = validateOUpdatePW(
    await doAPIPostRequest("user/update/password", undefined, args)
  );
  return ret;
};

export const goonoUpdateLoginUserPW = async (
  token: string,
  args: IUpdateLoginUserPW
): Promise<OUpdatePW> => {
  const ret = validateOUpdatePW(
    await doAPIPostRequest("login/user/update/password", token, args)
  );
  return ret;
};

export const goonoUpdateAccount = async (
  token: string,
  args: IUserModify
): Promise<OUserModify> => {
  const ret = validateOUserModify(
    await doAPIPostRequest("user/modify", token, args)
  );
  return ret;
};
