import T from "@redwit-commons/utils/typecheck";

export type MonthlyGraphsDataObject = {
  newUsersCount: number;
  paidUsersCount: number;
  start: string;
  end: string;
};

export type GraphsDataObject = {
  newUsersOfThisMonth: MonthlyGraphsDataObject;
  newUsers: MonthlyGraphsDataObject[];
};

export const MonthlyGraphsDataObjectSchema = T.object()
  .addField("newUsersCount", T.number())
  .addField("paidUsersCount", T.number())
  .addField("start", T.string())
  .addField("end", T.string());

export const GraphsDataObjectSchema = T.object()
  .addField("newUsersOfThisMonth", MonthlyGraphsDataObjectSchema.clone())
  .addField("newUsers", T.array(MonthlyGraphsDataObjectSchema.clone()));
