import T from "@redwit-commons/utils/typecheck";
import {
  GoonoResponse,
  mkResponseSchema,
  GoonoArrayResponse,
  mkArrayResultsSchema,
  GoonoPendingTasks,
} from "./common";
import {
  UserLicenseWithUserLog,
  UserLicenseWithUserLogSchema,
} from "../object/license";
import { GraphsDataObject, GraphsDataObjectSchema } from "../object/graphs";
import {
  ParsedTaskObject,
  ParsedTaskObjectSchema,
} from "@redwit-commons/object/task";

export type OGetAllUsers = GoonoArrayResponse<UserLicenseWithUserLog>;

export type OGetGraphsData = GoonoResponse<GraphsDataObject>;

export const OGetAllUsersSchema = mkArrayResultsSchema(
  UserLicenseWithUserLogSchema
);
export const OGetGraphsDataSchema = mkResponseSchema(GraphsDataObjectSchema);

export const validateOGetAllUsers =
  T.mkValidator<OGetAllUsers>(OGetAllUsersSchema);
export const validateOGetGraphsData =
  T.mkValidator<OGetGraphsData>(OGetGraphsDataSchema);

export type OAdminUserModify = GoonoResponse<{ user_id: string }> &
  GoonoPendingTasks<{ admin_task: string }>;
export const OAdminUserModifySchema = mkResponseSchema(
  T.object().addField("user_id", T.string()),
  T.object().addField("admin_task", T.string())
);
export const validateOAdminUserModify = T.mkValidator<OAdminUserModify>(
  OAdminUserModifySchema
);

export type OAdminUserModifyPending = GoonoArrayResponse<ParsedTaskObject>;
export const OAdminUserModifyPendingSchema = mkArrayResultsSchema(
  ParsedTaskObjectSchema
);
export const validateOAdminUserModifyPending =
  T.mkValidator<OAdminUserModifyPending>(OAdminUserModifyPendingSchema);
