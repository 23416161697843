import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { UserObject } from "@goono-commons/api/object/user";

export enum TokenStateStatus {
  INIT = "TokenState::INIT",
  SUCCESS = "TokenState::SUCCESS",
  SUCCESS_CHECK = "TokenState::SUCCESS_CHECK",
}

export enum TokenActionKind {
  TRY_LOGIN = "TokenAction::TRY_LOGIN",
  TRY_LOGOUT = "TokenAction::TRY_LOGOUT",
}

export type LoginUserInfo = {
  readonly token: string;
} & UserObject;

export type TokenState =
  | {
      readonly status: TokenStateStatus.INIT;
    }
  | ({
      readonly status: TokenStateStatus.SUCCESS;
    } & LoginUserInfo);

export type TokenAction =
  | ({
      readonly kind: TokenActionKind.TRY_LOGIN;
    } & LoginUserInfo)
  | {
      readonly kind: TokenActionKind.TRY_LOGOUT;
    };

export type TokenError = never;

const smid = "TOKEN_STATE_MACHINE3";

export type TokenStateMachineType = StateMachine3<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>;

export const tokenStateMachine: TokenStateMachineType = new StateMachine3<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>(smid, { status: TokenStateStatus.INIT }, [
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_LOGIN
  ),
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_LOGIN
  ),
  transition(
    TokenStateStatus.SUCCESS,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_LOGOUT
  ),
]);

export type DispatchTokenAction = Dispatch<
  StateMachineAction<
    TokenStateStatus,
    TokenState,
    TokenActionKind,
    TokenAction,
    TokenError
  >
>;

export default mkReducer<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>(tokenStateMachine);

export const doTokenAction = (
  dispatch: DispatchTokenAction,
  nextAction: TokenAction,
  onResolve: () => void = () => {},
  onReject: (err: TokenError | InternalError) => void = () => {}
) => {
  dispatch(tokenStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doTokenActionAsync = (
  dispatch: DispatchTokenAction,
  nextAction: TokenAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(tokenStateMachine.newTryAction(nextAction, resolve, reject));
  });
};

export const resetToken = (dispatch: DispatchTokenAction) => {
  dispatch(tokenStateMachine.newResetAction());
};
