import { makeStyles } from "@material-ui/core";
import { theme } from "@theme";

export const useBaseGridStyles = makeStyles(() => ({
  private_page_container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "calc(100vh - 64px)",
    backgroundColor: theme.gray_0,
  },
  loading_view_container: {
    width: `100%`,
    height: `100vh`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    "& svg": {
      color: theme.primary,
    },
  },
}));
