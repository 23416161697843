import React from "react";
import GlobalComponentManager from "./GlobalComponentManager";
import Router from "./router";

export default function RoutingManager() {
  return (
    <>
      <GlobalComponentManager />
      <Router />
    </>
  );
}
