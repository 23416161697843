import React from "react";
import GlobalModalManager from "./GlobalModalManager";
import GlobalSnackbarManager from "./GlobalSnackbarManager";

export default function GlobalComponentManager() {
  return (
    <React.Fragment>
      <GlobalModalManager />
      <GlobalSnackbarManager />
    </React.Fragment>
  );
}
