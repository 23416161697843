import { useState, useEffect } from "react";

type Props = {
  timeoutDuration?: number;
};

function useDebounceWindowWidth(props: Props = {}): number {
  const { timeoutDuration = 300 } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [resizeTimeout, setResizeTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const handleResize = () => {
    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }

    const newTimeout = setTimeout(() => {
      setWindowWidth(window.innerWidth);
      setResizeTimeout(null);
    }, timeoutDuration);

    setResizeTimeout(newTimeout);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
    };
  }, [resizeTimeout, timeoutDuration]);

  return windowWidth;
}

export default useDebounceWindowWidth;
