const ScreenURL = {
  /** auth */
  LOGIN: "/sign-in",
  /** manage */
  MANAGE_USER: "/manage-user",
  MANAGE_WORKSPACE: "/manage-workspace",
  MANAGE_ORGANIZATION: "/manage-organization",
  MANAGE_ORGANIZATION_ADMIN: "/manage-organization-admin",
  /** configuration */
  WORKSPACE_CREATE: "/workspace/create",
  WORKSPACE_EDIT: "/workspace/edit/:workspace_id",

  ORGANIZATION_CREATE: "/organization/create",
  ORGANIZATION_EDIT: "/organization/edit/:organization_id",
  /** log */
  /** @deprecated */
  ACCESS: "/access",
  /** @deprecated */
  DASHBOARD: "/dashboard",
  /** @deprecated */
  REPORT_USER_LOG: "/users",
  /** payment */
  PAYMENT_WINDOW_CREATION: "/credit",
  PAYMENT_WINDOW_CREATION_HISTORY: "/payment-history",
};

export default ScreenURL;
