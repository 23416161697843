import React from "react";
import { Grid } from "@material-ui/core";
import { useBaseGridStyles } from "./styles/BaseGrid";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { TokenStateStatus } from "src/redux/store/reducers/token";
import { useNavigate } from "react-router-dom";
import ScreenURL from "src/routes/route_list";
import ServiceHeader from "src/navs/ServiceHeader";
import loadable from "@loadable/component";

interface BaseGridProps {
  children: React.ReactNode;
}

const SidebarLazy = loadable(() => import(`src/navs/ServiceSidebar`));

export default function BaseGrid(props: BaseGridProps) {
  const classes = useBaseGridStyles();
  const token = useSelector((state: RootState) => state.token);
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  React.useEffect(() => {
    if (token.state.status !== TokenStateStatus.SUCCESS)
      navigate(ScreenURL.LOGIN, { replace: true });
  }, []);

  return (
    <React.Fragment>
      {sidebarOpen && (
        <SidebarLazy
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      )}
      <ServiceHeader
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Grid className={classes.private_page_container}>{props.children}</Grid>
    </React.Fragment>
  );
}
