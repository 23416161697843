import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import {
  TokenState,
  TokenAction,
  TokenStateStatus,
  TokenStateMachineType,
  TokenActionKind,
  tokenStateMachine,
} from "../store/reducers/token";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.token,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type TokenContainerProps = PropsFromRedux & {
  stateMachine: TokenStateMachineType;
};

class TokenContainer extends ReduxStateComponent3<TokenContainerProps> {
  static defaultProps = {
    stateMachine: tokenStateMachine,
  };

  constructor(props: TokenContainerProps) {
    super(props);
  }

  protected onAction(_prevState: TokenState, action: TokenAction): TokenState {
    switch (action.kind) {
      case TokenActionKind.TRY_LOGIN: {
        return {
          ...action,
          status: TokenStateStatus.SUCCESS,
        };
      }

      case TokenActionKind.TRY_LOGOUT: {
        return { status: TokenStateStatus.INIT };
      }
    }
  }
}

export default connector(TokenContainer);
